import { orderBy } from "lodash";
import { isCommunityAdmin } from "@/react/helpers/communityMemberHelpers";
import { spaces } from "@circle-react/helpers/urlHelpers";
import type { CurrentCommunityMember } from "@circle-react/types/CurrentCommunityMember";
import type { Space } from "@circle-react/types/Space";
import type { SpaceGroup } from "@circle-react/types/SpaceGroup";

interface UserRoles {
  current_user_roles?: string[];
}

export const userIsAdminOrModeratorOfSpace = ({
  current_user_roles,
}: UserRoles = {}) =>
  Boolean(
    current_user_roles &&
      (current_user_roles.includes("admin") ||
        current_user_roles.includes("moderator")),
  );

interface SpaceMember {
  is_space_member?: boolean;
}

export const isSpaceMember = ({ is_space_member }: SpaceMember = {}) =>
  Boolean(is_space_member);

export const isEventsSpace = (space?: Partial<Space>) =>
  space?.post_type === "event";

export const isBasicPostsSpace = (space?: Partial<Space>) =>
  space?.post_type === "basic";

export const isChatSpace = (space?: Partial<Space>) =>
  space?.post_type === "chat" || space?.space_type === "chat";
export const isCourseSpace = (space?: Partial<Space>) =>
  space?.post_type === "course";
export const isMembersSpace = (space?: Partial<Space>) =>
  space?.post_type === "members";
export const isImagesSpace = (space?: Partial<Space>) =>
  space?.post_type === "image";

export const isFeedViewSpace = (space?: Partial<Space>) =>
  space?.display_view === "posts" && !isEventsSpace(space);

export const isCardViewSpace = (space?: Partial<Space>) =>
  space?.display_view === "cards";

export const isListViewSpace = (space?: Partial<Space>) =>
  space?.display_view === "list";

export const isFeedViewImageSpace = (space?: Partial<Space>) =>
  Boolean(space && isImagesSpace(space) && space.display_view === "feed");

export const isMasonryViewImageSpace = (space?: Partial<Space>) =>
  Boolean(space && isImagesSpace(space) && space.display_view === "masonry");

export const isGridViewImageSpace = (space?: Partial<Space>) =>
  Boolean(space && isImagesSpace(space) && space.display_view === "grid");

export const isGridOrMasonryViewImageSpace = (space?: Partial<Space>) =>
  isMasonryViewImageSpace(space) || isGridViewImageSpace(space);

export const isPrivateSpace = (space?: Partial<Space>) =>
  Boolean(space?.is_private);

export const isPublicSpace = (space?: Partial<Space>) =>
  Boolean(space && !isPrivateSpace(space));

export const isSecretSpace = (space?: Partial<Space>) =>
  space?.visibility === "secret";
export const isDraftSpace = (space?: Partial<Space>) =>
  space?.visibility === "draft";

export const canViewSpaceContent = (
  space?: Partial<Space>,
  communityMember?: CurrentCommunityMember,
) =>
  !isPrivateSpace(space) ||
  isSpaceMember(space) ||
  isCommunityAdmin(communityMember);

export const canCreatePostInSpace = (space?: Partial<Space>) =>
  Boolean(space?.policies?.can_create_post);

export const canCreateEventInSpace = (space?: Partial<Space>) =>
  canCreatePostInSpace(space) && isEventsSpace(space);

export const canInviteMembers = (space?: Partial<Space>) =>
  Boolean(space?.policies?.can_invite_members);

export const canCreatePostInBasicSpace = (space?: Partial<Space>) =>
  canCreatePostInSpace(space) && isBasicPostsSpace(space);

export const canCreatePostInImageSpace = (space?: Partial<Space>) =>
  canCreatePostInSpace(space) && isImagesSpace(space);

export const canCreatePostInBasicOrImageSpace = (space?: Partial<Space>) =>
  canCreatePostInSpace(space) &&
  (isBasicPostsSpace(space) || isImagesSpace(space));

export const canManageSpace = (space?: Partial<Space>) =>
  Boolean(space?.policies?.can_manage_space);

export const spaceAccessViaPaywall = (space?: Partial<Space>) =>
  space?.space_access_type === "paywall";

export const hasEmoji = (space?: Partial<Space>) =>
  Boolean(space?.emoji && space.emoji !== "#");

export const isSortingEnabled = (space?: Partial<Space>) =>
  !space?.hide_sorting;

export const isTabEnabled = (space?: Partial<Space>, tab?: string) =>
  Boolean(space && tab && space?.visible_tabs?.[tab]);

export const spacePostsPageExist = (space?: Partial<Space>) =>
  isTabEnabled(space, "posts");

export const spaceMembersPageExist = (space?: Partial<Space>) =>
  isTabEnabled(space, "members");

export const spaceUpcomingEventsPageExist = (space?: Partial<Space>) =>
  isTabEnabled(space, "upcoming");

export const spacePastEventsPageExist = (space?: Partial<Space>) =>
  isTabEnabled(space, "past");

export const canAccessSpaceMembersPage = (
  space?: Partial<Space>,
  communityMember?: CurrentCommunityMember,
) =>
  spaceMembersPageExist(space) &&
  (communityMember?.is_admin || space?.is_space_member || !space?.is_private);

export const canShowPinnedPostSidebar = (
  space?: Partial<Space>,
  communityMember?: CurrentCommunityMember,
) =>
  Boolean(
    space &&
      (!space.is_private || space.is_space_member || communityMember?.is_admin),
  );

export const hasContentPages = (space?: Partial<Space>) => {
  if (isEventsSpace(space)) {
    return (
      spaceUpcomingEventsPageExist(space) || spacePastEventsPageExist(space)
    );
  }

  if (isMembersSpace(space)) {
    return true;
  }

  return spacePostsPageExist(space);
};

export const shouldRedirectToSpaceMembersPage = (space?: Partial<Space>) =>
  !isMembersSpace(space) &&
  !hasContentPages(space) &&
  canAccessSpaceMembersPage(space);

export const shouldShowSpaceContent = (space?: Partial<Space>) =>
  Boolean(space?.id && hasContentPages(space));

export const spaceMetaTitle = (space?: Partial<Space>) =>
  space?.meta_tag_attributes?.meta_title
    ? space.meta_tag_attributes.meta_title
    : space?.name;

interface SortSpacesProps {
  spaces: Space[];
  sort?: string;
}

export const sortSpaces = ({ spaces, sort = "none" }: SortSpacesProps) => {
  switch (sort) {
    case "active":
      return orderBy(spaces, ["posts_count"], ["desc"]);
    case "latest":
      return orderBy(spaces, ["created_at"], ["desc"]);
    case "alphabetical":
      return orderBy(spaces, ["name"], ["asc"]);
    default:
      return spaces;
  }
};

export const useSpaceLinkWithDefaultTab = () => (space: Partial<Space>) => {
  if (isEventsSpace(space)) {
    return spaces.show({
      spaceSlug: space.slug,
      params: {
        sort: "asc",
      },
    });
  }
  return spaces.show({ spaceSlug: space.slug });
};

export const getSpaceNotificationDetail = (
  spaceNotifications: any[],
  spaceId: any,
) => spaceNotifications.find(spaceItem => spaceItem.id === spaceId);

export const isSpaceMemberGrantedByPaywall = (accessType: string) =>
  accessType === "paywall";

export const isSpaceCalendarView = (space?: Partial<Space>) =>
  space?.display_view === "calendar";

export const isEventSpaceCalendarView = (space?: Partial<Space>) =>
  isEventsSpace(space) && isSpaceCalendarView(space);

export const isSpaceVisibleOnSidebar = ({
  space,
  spaceGroup,
}: {
  space: Space;
  spaceGroup: SpaceGroup;
}) =>
  !space.hide_from_sidebar &&
  (!spaceGroup.hide_non_member_spaces_from_sidebar || space.is_space_member);
